import React, { useEffect } from "react"

import styled, { css } from "styled-components"
import font from "../../fonts/fonts.module.scss"
import MultimediaText from "../multimediaText";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";

// import required modules
import { Mousewheel, Pagination, Autoplay } from "swiper";


const SlideWithComponentCopy = ({ slide, bg, text, big, location, justify, white, paddingBottom }) => {
  var oneBorder = false
  if (slide.length < 2)
    oneBorder = false
  else oneBorder = true


  var options = {
    direction: "vertical",
    slidesPerView: 1,
    pagination: {
      clickable: oneBorder,
      currentClass: '.bullet-active',
      renderBullet: (index, className) => {
        return '<span class="' + className + '">'
          + '<svg ' + className + ' width="14.234" height="73.359" viewBox="0 0 14.234 73.359">'
          + '<path  d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"'
          + ' transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />'
          + '</svg></span>';
      }
    },
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    enabled: oneBorder,
    grabCursor: oneBorder,
    speed: 300,
    loop: oneBorder,
    modules: [Mousewheel, Pagination, Autoplay],
    className: "mySwiper"
  }

  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (location)
        if (location.hash !== "") {
          slide.filter((item, index) => {
            if (location.hash.includes(item.id)) {
              var swiper = document.getElementById('slide-service')
              swiper.swiper.slideTo(index)
              swiper.scrollIntoView();
            }
          })
        }
    }
  }, [location, slide]);

  return (
    <SlideWithComponentStyled text={text ? true : false} white={white ? true : false} paddingBottom={paddingBottom}
      style={{ backgroundImage: bg ? `url("${bg.background.childImageSharp.fluid.src}")` : '' }}>
      {text &&
        <div className="container-text-divider ml-5">
          <h2 className={font.oL + ' m-0'}>{text.title[0]}</h2>
          {text.title.length > 1 && <h2 className={font.oL + ' uppercase m-0'}>{text.title[1]}</h2>}
          <div className="container-svg m-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.234" height="73.359" viewBox="0 0 14.234 73.359"><path fill="#ab2c29" d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z" transform="translate(0 73.359) rotate(-90)" /></svg>
          </div>
          <h1 className={font.oR + " text bold uppercase m-0"}>{text.subtitle}</h1>
        </div>
      }

      <SlideStyled big={big ? 'auto' : '45%'}>
        <Swiper
          id="slide-service"
          {...options}
        >
          {slide.map((value, index) => (
            <SwiperSlide key={'slide-' + index}>
              <div className='container-slide '>
                <MultimediaText data={value} inverse={true} video={false} slide={true} justify={justify} white={white} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </SlideStyled>
    </SlideWithComponentStyled>
  )
}

export default SlideWithComponentCopy


const SlideStyled = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;
    
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets {
    left: 1% !important;
    width: 1%;
    display: grid;
    z-index:99;
    height: ${props => props.big && props.big};
    row-gap: 1vh;
  }

.swiper-pagination-bullet {
    background: transparent;
    opacity: 1;
    svg{
        height: 100%;
        width: 100%;
        max-height: 2em;
    }
    svg path 
    {
        opacity: 1;
        fill: #fff;
    }
}
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: transparent;
    svg{
        height: 100%;
        width: 100%;
        max-height: 2em;
    }
    svg path 
    {
        opacity: 1;
        fill: #ab2c29;
    }
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet{
    height: 100%;
    width: 100%;
}

  
`

const SlideWithComponentStyled = styled.div`
padding: 5vh 5vw 0vh 5vw;
background-repeat: no-repeat;
background-position: bottom;
background-size: cover;
height: 75vh;

${props => props.text && css`
padding: 15vh 5vw 15vh 5vw;
height: 100vh;
overflow: hidden;
`}

${props => props.white && css`
background-color: #fff;
.intro-text{
  color: #000 !important;
}`
  }
.uppercase{
  text-transform: uppercase;
}
.text{
  position: relative;
  z-index: 99;
  margin:auto;
}
.bold{
  font-weight: bold;
}

.ml-5{
  margin-left: 5%;
}
.m-0{
  margin: 0;
}
.container-svg{
  svg{
    transform: rotate(90deg);
    margin-left: 1vh;
    height: 4vh;
  }
}

  .container-slide{
    position: relative;
    .container-text{
      width: 90% !important;
    }
    .gatsby-image-wrapper{
      height: 50vh;
      width: 100%;
      object-fit: cover;
    }
    .container-text-title{
      position: absolute;
      top: 45vh;
      left: 20vh;
      text-transform: uppercase;
      z-index: 99;
    }
    .container-text-desciption{
      text-transform: initial;
      z-index: 99;
      width: 60%;
      padding-top: 2vh;
    }
  }

  .text-center{
    text-align: center !important;
  }

  .m-auto{
    margin:auto !important;
  }
 @media (min-width: 768px) and (max-width: 1024px) {
  .slick-vertical{
      height: 60vh !important;
    }
  }
  @media (max-width: 990px){
    padding: 10vh 5vw 0vh 5vw;
    ${props => props.paddingBottom && css`
padding-bottom: ${props.paddingBottom} !important;
`}

    .slick-vertical{
      margin-top: 6vh;
      height: 80vh;
      max-height: 80vh;
      .slick-list{
        margin-top: 0;
      }
      .slick-slide{
        height: 55vh !important;
      }
    }

    .slick-list {
      height: 55vh !important;
      margin-top: 7vh;
    }
  
    .container-slide{
      height: 55vh;
      margin-top: 5vh !important;
      margin-left: 3%;
      width: 90% !important;
      .gatsby-image-wrapper, img{
        height: 25vh !important;
        width: 100%;
        object-fit: cover;
      }
      .gatsby-image-wrapper{
        margin-top: 0 !important;
        margin-bottom: auto !important;
      }
      .container-text-title{
        left: 10vh;
      }
    }

    .vertical-dots{
      display: inline-flex !important;
      top: 0vh !important;
      margin-top: 0;
      left: 3vh;
      li{
        transform: rotate(90deg) !important;
        margin-right: 9.2vw !important;
      }
    }

    .intro-title{
      width: 80%;
    }


    .slick-slide{
      visibility: hidden !important;
      height: 55vh;
    }
    .slick-active{
      visibility: visible !important;
    }
  }
`