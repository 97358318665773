import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import styled from "styled-components"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import DividerBorder from "../components/dividerBorder";
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import SlideWithComponent from "../components/sliderSwiper/slideWithComponent";
import SwiperWithComponentMobile from "../components/sliderSwiper/swiperWithComponentMobile";
import MultimediaText from "../components/multimediaText";
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const MotorradPage = ({ data, location }) => (
  <>
    <SEO title="Motorrad" image={data.motorradJson.intro.background.childImageSharp.fluid.src} />
    <Layout>
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile data={data.globalJson.header} />

          <SliderSwiper data={[data.motorradJson.introMobile]} center top />

          <DividerBorder text={false} inverse={false} mobile={true} />

          <MotorradPageStyled>
            <div className="containers-multimedia-text">
              {data.motorradJson.multimediaTextVideo.map((item, index) => (
                <div key={'multimedia-text-' + index} className="container-multimedia-text">
                  <MultimediaText data={item} inverse={index % 2 ? true : false} video={true} slide={false} justify />
                  {(index < (data.motorradJson.multimediaTextVideo.length - 1)) && <hr />}
                </div>
              ))}
            </div>

          </MotorradPageStyled>

          <DividerBorder text={false} inverse={true} mobile={true} />
          
          <SwiperWithComponentMobile slide={data.motorradJson.mobile.multimediaText} location={location} justify/>

          <FooterMobile data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
        </>
      ) : (
        <>
          <Header data={data.globalJson.header} />

          <SliderSwiper data={[data.motorradJson.intro]} center oneSlideBorder={false} top />

          <DividerBorder text={false} inverse={false} mobile={false} />

          <MotorradPageStyled>
            <div className="containers-multimedia-text">
              {data.motorradJson.multimediaTextVideo.map((item, index) => (
                <div key={'multimedia-text-' + index} className="container-multimedia-text">
                  <MultimediaText data={item} inverse={index % 2 ? true : false} video={true} slide={false} justify />
                  {(index < (data.motorradJson.multimediaTextVideo.length - 1)) && <hr />}
                </div>
              ))}
            </div>

          </MotorradPageStyled>

          <DividerBorder text={false} inverse={true} />

          <SlideWithComponent slide={data.motorradJson.multimediaText} big location={location} justify/>

          <Footer data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
        </>
      )}
    </Layout>
  </>
)

export default MotorradPage

export const Json = graphql`
    query motorrad {
      motorradJson {
      intro {
      background {
      childImageSharp {
      fluid(quality: 100, maxWidth: 3400) {
      ...GatsbyImageSharpFluid_withWebp
    }
          }
        }
    title
    description
      }
    introMobile {
      background {
      childImageSharp {
      fluid(quality: 100, maxWidth: 768) {
      ...GatsbyImageSharpFluid_withWebp
    }
          }
        }
    title
    description
      }
    multimediaTextVideo {
      btnClass
      btnLink
      description
      btnText
      id
      title
      video
      img {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3400) {
            src
            srcWebp
          }
        }
      }
    }
    multimediaText {
      btnClass
      btnLink
      description
      btnText
      id
      title
      video
      img {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3400) {
            src
            srcWebp
          }
        } 
      }
    }
    mobile{
      multimediaText {
        btnClass
        btnLink
        description
        btnText
        id
        title
        video
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              src
              srcWebp
            }
          } 
        }
      }
    }
    }
    globalJson {
      header {
      links {
      link
          linkTxt
        }
    sidemenu {
      links {
      link
            linkTxt
    extraLinks {
      link
              linkTxt
            }
          }
    madeBy
        }
      }
    floatButtons{
      btn
        btnLink
      }
    footer {
      linksLeft {
      link
          linkTxt
        }
    linksRight {
      link
          linkTxt
        }
    image {
      childImageSharp {
      fluid(maxWidth: 198, quality: 100) {
      ...GatsbyImageSharpFluid
    }
          }
        }
      }
    }
  }
    `
const MotorradPageStyled = styled.div`
    .containers-multimedia-text{
      padding: 5vh 5vw 5vh 5vw;
      background-color: #fff;
      .intro-text{
        color: #000 !important;
      }
    }
    hr{
      margin - top: 5vh;
    margin-bottom: 5vh;
    height: 1px;
    background-color: #5A5A5A;
    border: none;
    }


    `