import React from "react"
import styled from "styled-components"
import font from "../fonts/fonts.module.scss"

const DividerLeft = () => {
  return (
    <svg id="Grupo_622" data-name="Grupo 622" xmlns="http://www.w3.org/2000/svg" width="411.094" height="73.92" viewBox="0 0 411.094 73.92" style={{ transform: 'scaleX(-1)' }}>
      <defs>
        <clipPath id="clipPath">
          <rect id="Retângulo_666" data-name="Retângulo 666" width="411.094" height="73.92" fill="none" />
        </clipPath>

        <clipPath id="clip-path-2">
          <path id="Caminho_2172" data-name="Caminho 2172" d="M36.2,36.275l338.739.771L36.2,37.816c-1.1,0-1.991-.34-2-.765s.878-.774,1.973-.776Z" transform="translate(-34.2 -36.275)" fill="none" />
        </clipPath>
        <linearGradient id="linear-gradient" x1="1.097" y1="24.381" x2="1.094" y2="24.381" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ededed" />
          <stop offset="0.007" stopColor="#ededed" />
          <stop offset="0.335" stopColor="#706f6f" />
          <stop offset="0.537" stopColor="#848483" />
          <stop offset="0.808" stopColor="#9d9d9c" />
          <stop offset="1" stopColor="#9d9d9c" />
        </linearGradient>
      </defs>
      <g id="Grupo_576" data-name="Grupo 576">
        <g id="Grupo_575" data-name="Grupo 575" clip-path="url(#clip-path)">
          <g id="Grupo_621" data-name="Grupo 621">
            <rect id="Retângulo_665" data-name="Retângulo 665" width="411.094" height="71.535" fill="url(#pattern)" />
          </g>
        </g>
      </g>
      <g id="Grupo_577" data-name="Grupo 577" transform="translate(37.261 36.275)" clipPath="url(#clipPath-2)">
        <rect id="Retângulo_667" data-name="Retângulo 667" width="340.743" height="1.544" transform="translate(-0.006 0)" fill="url(#linear-gradient)" />
      </g>
    </svg>


  )
}

const DividerRigth = () => {
  return (
    <svg id="Grupo_622" data-name="Grupo 622" xmlns="http://www.w3.org/2000/svg" width="411.094" height="73.92" viewBox="0 0 411.094 73.92">
      <defs>
        <clipPath id="clipPath">
          <rect id="Retângulo_666" data-name="Retângulo 666" width="411.094" height="73.92" fill="none" />
        </clipPath>

        <clipPath id="clipPath-2">
          <path id="Caminho_2172" data-name="Caminho 2172" d="M36.2,36.275l338.739.771L36.2,37.816c-1.1,0-1.991-.34-2-.765s.878-.774,1.973-.776Z" transform="translate(-34.2 -36.275)" fill="none" />
        </clipPath>
        <linearGradient id="linear-gradient" x1="1.097" y1="24.381" x2="1.094" y2="24.381" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ededed" />
          <stop offset="0.007" stopColor="#ededed" />
          <stop offset="0.335" stopColor="#706f6f" />
          <stop offset="0.537" stopColor="#848483" />
          <stop offset="0.808" stopColor="#9d9d9c" />
          <stop offset="1" stopColor="#9d9d9c" />
        </linearGradient>
      </defs>
      <g id="Grupo_576" data-name="Grupo 576">
        <g id="Grupo_575" data-name="Grupo 575" clipPath="url(#clipPath)">
          <g id="Grupo_621" data-name="Grupo 621">
            <rect id="Retângulo_665" data-name="Retângulo 665" width="411.094" height="71.535" fill="url(#pattern)" />
          </g>
        </g>
      </g>
      <g id="Grupo_577" data-name="Grupo 577" transform="translate(37.261 36.275)" clipPath="url(#clipPath-2)">
        <rect id="Retângulo_667" data-name="Retângulo 667" width="340.743" height="1.544" transform="translate(-0.006 0)" fill="url(#linear-gradient)" />
      </g>
    </svg>

  )
}

const DividerBorder = ({ text, inverse, mobile, campanha }) => {
  console.log(text)
  if (campanha) {
    return (
      <DividerBorderCampanhaStyled>

        <div className="bg-border">
        </div>
        {text &&
          <div className="container-border-divider-text">
            {mobile ?
              <div className="container-text-divider ">
                <h2 className={font.oR + " text bold uppercase"}>{text.subtitle}</h2>
              </div>
              :

              <div className="grid three-columns">
                <div className="container-svg-divider">
                  <DividerLeft />
                </div>
                <div className="container-text-divider ">
                  <h2 className={font.oR + " text bold uppercase"}>{text.subtitle}</h2>
                </div>
                <div className="container-svg-divider">
                  <DividerRigth />
                </div>
              </div>

            }
          </div>
        }

      </DividerBorderCampanhaStyled>
    )
  } else {
    if (!inverse) {
      return (
        <DividerBorderStyled>

          <div className="bg-border">
          </div>
          {text &&
            <div className="container-border-divider-text">
              {mobile ?
                <div className="container-text-divider ">
                  <h5 className={font.oR + " text  uppercase"}>{text.title}</h5>
                  <h2 className={font.oR + " text bold uppercase"}>{text.subtitle}</h2>
                </div>
                :

                <div className="grid three-columns">
                  <div className="container-svg-divider">
                    <DividerLeft />
                  </div>
                  <div className="container-text-divider ">
                    <h5 className={font.oR + " text  uppercase"}>{text.title}</h5>
                    <h2 className={font.oR + " text bold uppercase"}>{text.subtitle}</h2>
                  </div>
                  <div className="container-svg-divider">
                    <DividerRigth />
                  </div>
                </div>

              }
            </div>
          }

        </DividerBorderStyled>
      )
    }
    else if (inverse) {
      return (
        <DividerBorderInverseStyled>

          <div className="bg-border">
          </div>

        </DividerBorderInverseStyled>
      )
    }
  }
}

export default DividerBorder

const DividerBorderStyled = styled.div`
width: 100%;
z-index: 99;
background-color: #fff;
position: absolute;
.bg-border{
  height: 10vh;
  margin-top: -9.8vh;
  z-index: 99;
  width: 80%;
  position: absolute;
  border-bottom: 10vh solid #fff;
  border-right: 4vh solid transparent;
  border-top-right-radius: 3vw;
}
.container-border-divider-text{
  position: absolute;
  width: 100%;
  margin: auto;
  margin-top: -9.3vh;
  height: 10vh;
  .grid{
    display: grid;
    height: 100%;
  }
  .three-columns{
    grid-template-columns: 33% 33% 33%;
    width: 55%;
    margin: auto;
  }
  .container-svg-divider{
    height: 100%;
    width: 100%;
    margin: auto;
    align-items: center;
    position: relative;
    overflow: hidden;
    svg{
      position: relative;
      z-index: 99;
      height: 100%;
      fill: white;
      width: fit-content;
      max-width: 100%;
    }
  }
  .container-text-divider{
    text-align: center;
    align-self: center;
    .uppercase{
      text-transform: uppercase;
    }
    .text{
      position: relative;
      z-index: 99;
      margin:auto;
      color: #000;
    }
    .bold{
      font-weight: bold;
    }
  }
}

@media (max-width: 990px){
  .bg-border {
    margin-top: -8.8vh;
  }
  .container-border-divider-text{
    margin-top: -7vh;
  }
  .container-text-divider {
    text-align: left !important;
    margin-left: 5vh !important;
  }
}

@media (min-width: 900px) and (max-width: 1024px){
  .container-border-divider-text {
    margin-top: -9vh !important;
    .three-columns {
      grid-template-columns: 20% 60% 20% !important;
      .container-text-divider{
        text-align: center !important;
      }
    }
  }
}
 
`
const DividerBorderInverseStyled = styled.div`
width: 100%;
z-index: 90;
background-color: transparent;
position: absolute;

.bg-border{
  right: 0;
    height: 10vh;
    margin-top: -0.3vh;
    z-index: 99;
    width: 80%;
    float: right;
    position: absolute;
    border-bottom: 10vh solid #fff;
    border-left: 4vh solid transparent;
    border-top-left-radius: 3vw;
    transform: rotateX(180deg);
  
}

@media (min-width: 991px) and (max-width: 1024px){
  .container-border-divider-text .three-columns {
    grid-template-columns: 20% 60% 20% !important;
  }
}

@media (max-width: 990px){
  .bg-border {
    margin-top: -5.8vh;
  }
}


`
const DividerBorderCampanhaStyled = styled.div`
width: 100%;
z-index: 99;
background-color: #000;
position: absolute;
.bg-border{
  height: 10vh;
  margin-top: -9.8vh;
  z-index: 99;
  width: 80%;
  position: absolute;
  border-bottom: 10vh solid #000;
  border-right: 4vh solid transparent;
  border-top-right-radius: 3vw;
}
.container-border-divider-text{
  position: absolute;
  width: 100%;
  margin: auto;
  margin-top: -9.3vh;
  height: 10vh;
  .grid{
    display: grid;
    height: 100%;
  }
  .three-columns{
    grid-template-columns: 33% 33% 33%;
    width: 55%;
    margin: auto;
  }
  .container-svg-divider{
    height: 100%;
    width: 100%;
    margin: auto;
    align-items: center;
    position: relative;
    overflow: hidden;
    svg{
      position: relative;
      z-index: 99;
      height: 100%;
      fill: white;
      width: fit-content;
      max-width: 100%;
    }
  }
  .container-text-divider{
    text-align: center;
    align-self: center;
    .uppercase{
      text-transform: uppercase;
    }
    .text{
      position: relative;
      z-index: 99;
      margin:auto;
      color: #9B9898;
    }
    .bold{
      font-weight: bold;
    }
  }
}

@media (max-width: 990px){
  .bg-border {
    margin-top: -8.8vh;
  }
  .container-border-divider-text{
    margin-top: -7vh;
  }
  .container-text-divider {
    text-align: left !important;
    margin-left: 5vh !important;
  }
}

@media (min-width: 900px) and (max-width: 1024px){
  .container-border-divider-text {
    margin-top: -9vh !important;
    .three-columns {
      grid-template-columns: 20% 60% 20% !important;
      .container-text-divider{
        text-align: center !important;
      }
    }
  }
}
 
`