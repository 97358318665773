import React from "react"
import styled from "styled-components"
import font from "../fonts/fonts.module.scss"
import { navigate } from "gatsby"
import { Buttons } from './buttons'
import Img from "gatsby-image"

const MultimediaText = ({ data, inverse, video, slide, imgHide, justify, white }) => {
  var classIntroText = ' intro-text'

  if (justify) {
    classIntroText = classIntroText + ' text-justify'
  }
  return (
    <MultimediaTextStyled padding={slide ? '' : '5vh'} height={slide ? '55vh' : '50vh'} inverse={inverse ? true : false} justify={justify ? true : false}>
      {inverse ?
        <>
          <div className="container-text">
            <h1 className={white ? font.oEL + ' intro-text black ' : font.oEL + ' intro-text '}>
              {data.title}
            </h1>
            {data.subtitle &&
              <h4 className={white ? font.oL + ' uppercase subtitle-intro-text black' : font.oL + ' uppercase subtitle-intro-text'}>{data.subtitle}</h4>
            }
            <p className={font.oEL + classIntroText} dangerouslySetInnerHTML={{ __html: data.description }} />

            <div className={font.oEL + ' p-relative container-btn'} style={{ paddingTop: "2vh" }}>
              <Buttons text={data.btnText} actionFunction={() => { navigate(data.btnLink) }} btnClass={data.btnClass} />
            </div>

          </div>
          {!imgHide && <div className="container-multimedia">
            {video ?
              <iframe className="video" loading="lazy" src={data.video} title="YouTube video player - HCS" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              : (data.img?.childImageSharp) && <Img
                fluid={data.img.childImageSharp.fluid}
                alt={data.title}
              />
            }
          </div>
          }
        </>
        :
        <>
          <div className="container-multimedia">
            {video ?
              <iframe className="video" loading="lazy" src={data.video} title="YouTube video player - HCS" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              : (data.img?.childImageSharp) && <Img
                fluid={data.img.childImageSharp.fluid}
                alt={data.title}
              />
            }
          </div>
          <div className="container-text">
            <h1 className={font.oEL + ' intro-text'}>
              {data.title}
            </h1>
            <p className={font.oEL + classIntroText} dangerouslySetInnerHTML={{ __html: data.description }} />

            <div className={font.oEL + ' p-relative container-btn'} >
              <Buttons text={data.btnText} actionFunction={() => { navigate(data.btnLink) }} btnClass={data.btnClass} />
            </div>
          </div>
        </>
      }
    </MultimediaTextStyled>
  )
}

export default MultimediaText


const MultimediaTextStyled = styled.div`
  display: grid;
  grid-template-columns: ${props => props.inverse ? '40% 55%' : '55% 40% '};
  column-gap: 5%;
  align-items: center;
  padding-top: 0vh;
  padding-bottom: 0vh;
  width: 90%;
  margin: auto;
  height: ${(props) => props.height || '50vh'};
  align-items: center;

  padding-top: ${(props) => props.padding || '0vh'};
  padding-bottom: ${(props) => props.padding || '0vh'};

  .container-btn{
    width: 12vw;
    float: left;
    padding-top: 2vh;
  }
  .subtitle-intro-text{
    margin-top: -3vh;
  }
  .black{
    color: #000 !important;
  }
  
 
@media (max-width: 990px){
  display: block;
  min-height: 100%;
  height: 100%;
  padding-top: 0vh;
  .container-btn{
    width: 35vw;
    padding-top: 0;
  }
  .container-text{
    margin-top: 5vh !important;
  }
  hr{
    margin-bottom: 0vh;
  }
}
.uppercase, h1, h4{
    text-transform: uppercase;
}
.intro-title{
    margin: 0 0 0.5em;
    text-transform: uppercase;
    color: white;
}
.intro-text{
    margin: 4vh auto 4vh;
    color: white;
}
.text-justify{
  text-align: justify;
}
.container-multimedia{
  height: 100%;
  width: 100%;
  margin: auto;    
  display: grid;
  
  iframe{
    height: 100%;
    width: 100%;
    @media (min-width: 768px) and (max-width: 1024px) {
      min-height: 50vh;
    }
  }
  .gatsby-image-wrapper, img{
    align-self: center;
    height: 40vh !important;
    min-height: 40vh;
    max-height: 40vh;
    min-width: 40vw;
    width: 100%;
  }
}

.container-text{
  width: 100%;
  margin: auto;
  text-align: left;
}

.btn-black-border-red{
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='139.166' height='42.233' viewBox='0 0 139.166 42.233'%3E%3Cg id='Grupo_6' data-name='Grupo 6' transform='translate(-1085.518 -880)'%3E%3Cg id='Grupo_420' data-name='Grupo 420' transform='translate(1086.683 -2)'%3E%3Cpath id='Caminho_252' data-name='Caminho 252' d='M205.662,981.1a3.189,3.189,0,0,0,.865-.212,2.228,2.228,0,0,0,1.092-.971,4.372,4.372,0,0,0,.324-2.8l-4.238-24.326c-1.252-7.194-7.017-11.831-14.6-11.747l-8.9.1-109.173.349.608,3.947,5.536,31.783a5.511,5.511,0,0,0,1.345,2.807,3.28,3.28,0,0,0,2.245,1.247Z' transform='translate(-71.033 -58.041)' fill='none' stroke='%23ab2c29' strokeWidth='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 15vh;
    text-align: center;
    padding: 2vh;
    
    .intro-text{
        margin: 0;
    }
}
.btn-black-border-red:hover{
  .intro-text{
      color: #fff !important;
  }
}
.intro-text:hover{
  color: #fff !important;
}



`