import React, { useEffect } from "react"

import styled, { css } from "styled-components"
import MultimediaText from "../multimediaText";
import font from "../../fonts/fonts.module.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Parallax, Pagination, Navigation } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";

const SlideWithComponentCopy = ({ slide, text, small, location, justify, campanha }) => {
  var oneBorder = false
  if (slide.length < 2)
    oneBorder = false
  else oneBorder = true

  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (location)
        if (location.hash !== "") {
          slide.filter((item, index) => {
            if (location.hash.includes(item.id)) {
              var swiper = document.getElementById('slide-service')
              swiper.swiper.slideTo(index)
              swiper.scrollIntoView();
            }
          })
        }
    }
  }, [location, slide]);

  return (
    <SlideWithComponentStyled small={small ? true : false} campanha={campanha}>
      {text &&
        <div className="container-text-divider ml-5">
          <h2 className={font.oL + ' m-0'}>{text.title[0]}</h2>
          {text.title.length > 1 && <h2 className={font.oL + ' uppercase m-0'}>{text.title[1]}</h2>}
          <div className="container-svg m-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.234" height="73.359" viewBox="0 0 14.234 73.359"><path fill="#ab2c29" d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z" transform="translate(0 73.359) rotate(-90)" /></svg>
          </div>
          <h1 className={font.oR + " text bold uppercase m-0"}>{text.subtitle}</h1>
        </div>
      }
      <Swiper
        id="slide-service"
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        speed={600}
        parallax={true}
        pagination={{
          clickable: true,
          currentClass: 'bullet-active',
          renderBullet: (index, className) => {
            return '<span class="' + className + '">'
              + '<svg ' + className + ' width="14.234" height="73.359" viewBox="0 0 14.234 73.359">'
              + '<path  d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"'
              + ' transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />'
              + '</svg></span>';
          }
        }}
        autoplay={{
          delay: '9000'
        }}
        loop={oneBorder}
        navigation={false}
        modules={[Parallax, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {slide.map((value, index) => (
          <div key={'T' + index} >
            <SwiperSlide>
              <div
                slot="container-start"
                className="parallax-bg"
                style={{
                  backgroundImage: `url("${value.img.childImageSharp.fluid.src}")`
                }}
              ></div>
              <div className='container-slide ml-5' key={'slide-' + index}  >
                <MultimediaText data={value} inverse={true} video={false} slide={true} imgHide={true} justify={justify}/>
              </div>
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </SlideWithComponentStyled>
  )
}

export default SlideWithComponentCopy


const SlideWithComponentStyled = styled.div`
margin-top: 15vh;

.uppercase{
  text-transform: uppercase;
}
.text{
  position: relative;
  z-index: 99;
  margin:auto;
}
.bold{
  font-weight: bold;
}

.ml-5{
  margin-left: 5%;
}
.m-0{
  margin: 0;
}
.container-svg{
  svg{
    transform: rotate(90deg);
    margin-left: 1vh;
    height: 4vh;
    margin-bottom: -1vh;
  }
}

.container-text-divider{
  margin-bottom: 3vh;
}

.parallax-bg{
  width: 100vw;
  height: ${props => props.small === true ? '65vh' : '85vh'} ;
  position: relative;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.container-slide{
  position: absolute;
  z-index: 999;
  top: 15vh;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
  top: 2vh;
  width: auto;
  min-width: 95%;
  display: inline-flex;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  position: absolute;
  justify-content: left;
  margin-left: 5%;
  height: 10%;
  max-width: fit-content;
}
.swiper-pagination-bullets{
  width: 100%;
  height: 100%;
  display: inline-flex;
}
.swiper-pagination-bullet {
  background: transparent;
  transform: rotate(90deg);
  opacity: 1;
  max-height: 3em;
  height: 3em;
  width: 100%;
  max-width: 8vw;

  svg{
      height: 100%;
      width: 100%;
      max-height: 2em;
  }
  svg path 
  {
      opacity: 1;
      fill: #fff;
  }
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: transparent;
  svg{
      height: 100%;
      width: 100%;
      max-height: 2em;
  }
  svg path 
  {
      opacity: 1;
      fill: #ab2c29;
  }
}

${props => props.campanha && css`
.swiper-pagination-bullet:only-child {
  display: block !important;
}
     `}
`